.cards4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  height: auto;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  margin: 10px 10px 400px 10px; /* t, r, b, l */
  padding: 2%;

 /*margin: 20px 10px;
  padding: 10%;*/
}
  



.cards4 > h1 {
  margin-top: 0; /* Ensure it starts from top*/
  text-align: center;
  color: #5a2c7e;

  margin-bottom: 1rem;

  font-size: clamp(2rem, 5vw, 5rem);

  letter-spacing: -0.63px;
  font-weight: 900;

 
  
}

.cards4 > p {
  margin-bottom: 1.5rem;

  color: #5a2c7e;
  line-height: 1.5;
  font-size: max(1.2 rem, 1.2vw);
  text-align: center;
  margin-top: 0; /* Ensure it starts from top*/
}

.logo {
  display: flex;
  justify-content: center;

}


.logo > img { 
  border-radius: 6px;
  z-index: 2;
  transition: all 0.8s ease;
  object-fit: contain;
  width: 597px;
  height: 120px;
  margin-top: 0; /* Ensure it starts from top*/
   
 
}

@media screen and (max-width: 1200px) {
  .cards4 {
    grid-template-columns: 1fr;
  }

  .logo {


    display: block;
    margin-top: auto;
    padding: 1rem; /* Adjust padding for mobile*/
  }
}

@media screen and (max-width: 960px) {
  .cards4 {
    grid-template-columns: 1fr;
  }
  .logo {


    display: block;
    margin-top: auto;
    padding: 1rem; /* Adjust padding for mobile*/
  }
}

@media screen and (max-width: 768px) {
  .cards4 {
    flex-direction: column; /*this line added in the lower media queries to ensure the layout adjust properly*/
    padding-bottom: 20%;
    padding-left: 5px;
  }

  .logo  {
  
    display: block;
    margin-top: auto;
    padding: 1rem; /* Adjust padding for mobile*/
  }

}

@media screen and (max-width: 480px) {
  .cards4 {
    flex-direction: column;
    padding-bottom: 50%;
    padding-left: 2px
  }

  .logo {
    max-width: 100%;
    height: auto;
display: block;
    margin-top: auto;
    padding: 0.25rem; /* Adjust padding for mobile*/
  }
}

