.hero-img {
    background-color: transparent;
    /*background: rgba(0,0,0,.3);*/
    height: 100vh;
    width: 100%;
    position: relative;

    
}

.hero-img:before {
content:'';
background: url('/images/img49.jpg') no-repeat center center/cover;
height: 100%;
width: 100%;
position: absolute;
top: 0;
left: 0;
z-index: -1;
}

.hero-img .heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
 
  
}

.hero-img h1 {
    font-size: 7rem;
    letter-spacing: -0.63px;
  }
  
    
.hero-img p {
    font-size: 1.4rem;
}

@media screen and (max-width:640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}