* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

 
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

:root {
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  
}





/*.home,
.sobre-nosotras,
.sobre-nosotras-historia,
.sobre-nosotras-mision,
.sobre-nosotras-vision,
.sobre-nosotras-nuestro-equipo,
.eventos,
.servicios,
.programas,
.programas-familia,
.programas-disfruta-en-línea,
.programas-orientación-información,
.programas-oportunidades-laborales,
.boletin,
.unete-a-hwasa,
.unete-a-hwasa-socia,
.unete-a-hwasa-voluntaria,
.contact*/










