
.historia {
  
  width: 100%;
  margin:3rem 0 25rem;
  display:list-item; 
  grid-template-columns: repeat(2, 1fr);
  
  height: 100%;

  
  
  }
  
  .left .img-container {
  
    align-items: center;
  
  }
  
  .left .historia-me {
    max-width: 80%;
   
    grid-column: 1 / span 8;
    grid-row: 1;
    
    z-index: 6;
    border-radius: 3rem;
  
  
    margin: 16px 24px 8px 60px;
  }
  
  
  .historia .right{
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
  
    
  }
  .historia .right h2 {
    margin-bottom: 1rem;
  
    font-size: clamp(2rem, 5vw, 5rem);
    color: #5a2c7e;
  
    letter-spacing: -0.63px;
    font-weight: 500;
    text-align: justify;
  }
  .historia .right p {
    margin: 1.2rem 0;
    color: #5a2c7e;
    line-height: 1.7;
    font-size: clamp(1rem, 1.25vw, 1.25rem);
    text-align: justify;
  }

  .historia .right ul {
    margin: 1.2rem 0;
    color: #5a2c7e;
    line-height: 1.7;
    font-size: clamp(1rem, 1.25vw, 1.25rem);
    text-align: justify;
  }
  
 
  
  @media screen and (max-width: 768px) {
    .historia {
      /*grid-template-columns: 1fr;*/
      flex-direction: column
      
    }
  
    .historia .historia-me {
      max-width: 80%;
    }
  }
  
  @media screen and (max-width: 1140px) {
    .historia {
      /*grid-template-columns: 1fr;*/
      flex-direction: column
    }
  
    .historia .historia-me {
      max-width: 80%;
      
    } 
    

}