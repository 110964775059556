.cards {
  background-color: #e0dfdf center center/ cover no-repeat;
  box-shadow: #e0dfdf;

  height: 100%;
  width: 100%;

  /*display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;

  ;*/

  padding: 4rem 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 3rem calc((100vw - 1300px) / 2);
}

.cards-font {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
}

.cards-font > h1 {
  margin-bottom: 1rem;

  /*font-size: clamp(1rem, 10vw, 2rem);*/
  font-size: clamp(2rem, 5vw, 5rem);
  color: #5a2c7e;

  letter-spacing: -0.63px;
  font-weight: 900;

  /*text-align: center;
  color: #683491;
  font-weight: bold;
  font-size: 56px;

  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.63px;*/
}

.cards-font > p {
  margin-bottom: 2rem;
  letter-spacing: -0.005em;
color: #5a2c7e;
  line-height: 1.5em;
  /*font-size: max(1.2 rem, 1.2vw);*/
  font-size: clamp(1rem, 1.25vw, 1.25rem);
 

  /*text-align: center;
  color: #683491;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.4;
  padding-left: 2rem;
  padding-right: 2rem;
  transition-delay: 0.15s;
  padding-top: 2rem;*/
}



.cards > img {
  padding: 1rem 2rem;

  justify-content: center;
  align-items: flex-start;

  /*width: 400px;*/
  /*height: 300px;*/
  display: block;
  flex-direction: column;

  top: 0;
  left: 0;
  border-radius: 6px;
  z-index: 2;
  transform: scale(1.05);

  transition: all 0.8s ease;

  text-decoration: none;
  width: 100%;
  max-width: 400px;
  height: auto;

  object-fit: cover;

  margin-top: 6rem;

  /*display: block;
  width: 100%;
  height: auto;
  padding-left: 2rem;
  padding-right: 2rem;

  /*height: 100vh;
  width: 100%;

  justify-content: left;
  align-items: left;

  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);*/
}

.cards-btns {
  /*margin-top: 32px;*/
  margin-bottom: 3rem;
}

.cards-btns .btn {
  margin: 20px;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr;
  }

  .cards > img {
    width: 100%;
    height: 100%;
    display: block;
    margin-top: auto;
  
  }
}
