
.eventos {
    width: 100%;
    padding: 6rem 1rem;
    background-color: #000;

  
    }

  
  
.eventos-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
}
    
        
        
     



  @media screen and (max-width: 768px) {
    .eventos-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
    }
  
   
  
  
  @media screen and (max-width: 1140px) {
    .eventos-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
  
  
  }