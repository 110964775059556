

/*this part needs to be fix so navbar can be display when user scroll down*/

/*
.NavBar {

  display: flex;
   position: sticky;
  z-index: 999;
   top: 0;
 
 
   
   width: 60%;
   height: 80px;
   background: whitesmoke;
   /*background: linear-gradient(45deg, rgb(165, 207, 243), midnightblue);
   font-size: 1.2rem;
   align-items: center; */
 

 
 
 /*mobile */

 /*
.list {
  list-style-type: none;
  background: whitesmoke;
  
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  
  
  
  
  



}
.color-nav {


  margin-right: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #f1f1f1;
  cursor: pointer;
}


.btn {
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  color: #fff;
  font-size: 18px;
}

*/




@media screen and (max-width: 500px){
  
  .list {
    flex-direction: column;
    height: auto;
    
  }
  .color-nav:nth-child(1){
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    margin-top: 50px;
  }

  .color-nav {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    margin-right: 0px;
    padding: 20px 0;
  }

  .btn {
    display: block;
  }
}