.cards3 {
  /* background: linear-gradient(90deg, #ffffff 0%, #ffffff 80%);*/
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background-color: #f2b807;

  /*max-width: 1120px;
  /* width: 100%;


  margin: 0 auto;
  padding: 0;*/
}
