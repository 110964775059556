

.hero-container {


    background: url('/images/Image 1 - Hwasa.jpeg') center  
    center/ cover no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;

  position: relative;
  overflow: hidden;
  max-height: 1100px;

  min-height: 80vh;
  padding-top: 98.3667px;
  background-attachment: fixed; 
}

.hero-container > h1 {
  font-size: clam(1rem, 8vw, 2rem);
  font-weight: 400;
  /*text-transform: uppercase;*/
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  letter-spacing: -0.005em;
  margin-top: 250px;
  margin-bottom: -100px;
  margin-right: 180px;
  margin-left: 180px;

}

/* To move the picture */
.hero-btns {
  margin-top: 90px;
 
}
/* To make space between the buttons */
.hero-btns .btns {
  margin: 6px;
  display: inline-block;
}



@media screen and (max-width: 960px) {

  .hero-container {
    overflow: none;
  }
  .hero-container > h1 {
    font-size: 20px;
    margin-top: -100px;
    
  }
}

@media screen and (max-width: 768px) {

  .hero-container {
    overflow: none;
  }
  .hero-container > h1 {
    font-size: 20px;
    margin-top: -40px;

    text-align: center;
    padding: 2rem;
    width: 100%;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  
  }

  .btn {
    width: 100%;
  }
}

