.footer-container {
  background-color: #683491;
  color: white;
  padding: 2em;
  bottom: 0;
  width: 100%;
  position: absolute;
  
    /* background-color: #683491;
  color: white;
  padding-top: 3em;
  bottom: 0;
  width: 100%;
  position: absolute;
 opacity: 50%; this line doesn't go

*/

}

.col > h4 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #fff;
}

.list-unstyled {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}
.row {
  font-size: var(--font-size-body-x-small);
  line-height: var(--line-height-body-x-small);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.footer-links {
  flex-direction: column;
  display: grid;
  grid-gap: 1em;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-links a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}
/* Social Icons */
.social-icon-link {
  color: #fff;
}

.social-icon-link > i {
  color: #fff;
  font-size: 30px;
}

.col-sm a {
  color: #fff;
  text-decoration: none;
  
 
}
.col-sm a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}




@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  
}