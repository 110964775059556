
.oportunidades {
  
    width: 100%;
    margin:3rem 0 25rem;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    
    height: 100%;
    
   
    }
    
    .left .img-container {
    
      align-items: center;
    
    }
    
    .left .oportunidades-me {
      max-width: 80%;
     
      grid-column: 1 / span 8;
      grid-row: 1;
      
      z-index: 6;
      border-radius: 3rem;
    
    
      margin: 16px 24px 8px 60px;
    }
    
    
    .oportunidades .right{
      text-align: center;
      margin: auto;
      padding: 1rem 4rem;
    
      
    }
    .oportunidades .right h2 {
      margin-bottom: 1rem;
    
      font-size: clamp(2rem, 5vw, 5rem);
      color: #5a2c7e;
    
      letter-spacing: -0.63px;
      font-weight: 500;
    }
    .oportunidades .right p {
      margin: 1.2rem 0;
      color: #5a2c7e;
      line-height: 1.7;
      font-size: clamp(1rem, 1.25vw, 1.25rem);
    }
/*Second card */

    .descripcion-img .img-container2 {
    
        align-items: center;
      
      }
      
      .descripcion-img .descripcion-me {
        max-width: 80%;
       
        grid-column: 1 / span 8;
        grid-row: 1;
        
        z-index: 6;
        border-radius: 3rem;
      
      
        margin: 16px 24px 8px 60px;
      }
      
      
      .oportunidades .descripcion{
        text-align: center;
        margin: auto;
        padding: 1rem 4rem;
      
        
      }
      .oportunidades .descripcion h2 {
        margin-bottom: 1rem;
      
        font-size: clamp(2rem, 5vw, 5rem);
        color: #5a2c7e;
      
        letter-spacing: -0.63px;
        font-weight: 500;
      }
      .oportunidades .descripcion p {
        margin: 1.2rem 0;
        color: #5a2c7e;
        line-height: 1.7;
        font-size: clamp(1rem, 1.25vw, 1.25rem);
      }



    
    @media screen and (max-width: 768px) {
      .oportunidades {
        grid-template-columns: 1fr;
      }
    
      .oportunidades .oportunidades-me {
        max-width: 80%;
      }

     
    }
    
    @media screen and (max-width: 1140px) {
      .oportunidades {
        grid-template-columns: 1fr;
      }
    
      .oportunidades .oportunidades-me {
        max-width: 80%;
      }

    
    }