
.mision {
  
  width: 100%;
  margin:3rem 0 25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  height: 100%;
  
  
  }
  
  .left .img-container {
  
    align-items: center;
  
  }
  
  .left .mision-me {
    max-width: 80%;
   
    grid-column: 1 / span 8;
    grid-row: 1;
    
    z-index: 6;
    border-radius: 3rem;
  
  
    margin: 16px 24px 8px 60px;
  }
  
  
  .mision .right{
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
  
    
  }
  .mision .right h2 {
    margin-bottom: 1rem;
  
    font-size: clamp(2rem, 5vw, 5rem);
    color: #5a2c7e;
  
    letter-spacing: -0.63px;
    font-weight: 500;
  }
  .mision .right p {
    margin: 1.2rem 0;
    color: #5a2c7e;
    line-height: 1.7;
    font-size: clamp(1rem, 1.25vw, 1.25rem);
  }

  .mision .right ul {
    margin: 1.2rem 0;
color: white;
    line-height: 1.7;
    font-size: clamp(1rem, 1.25vw, 1.25rem);
   
   
  }
  .mision .right a{
    color: #5a2c7e;
 
  }

  .socia-btn {
    margin-top: 50px;
 

   
  }
  /* To make space between the buttons */
 .socia-btn .btns1 {
   margin-right: 50px;
   margin: 6px;
   display: inline-block;
   cursor: pointer;
   
  }
  
.socia-btn .btns2 {

  margin-left: 50px;
  margin: 6px;
  display: inline-block;
  cursor: pointer;
 


 
}
 
@media screen and (max-width: 1200px) {
  .mision {
    grid-template-columns: 1fr;
  }

  .mision .mision-me {
    max-width: 100%;
  }
} 
@media screen and (max-width: 1140px) {
  .mision {
    grid-template-columns: 1fr;
  }

  .mision .mision-me {
    max-width: 100%;
  }
} 

@media screen and (max-width: 960px) {
  .mision {
    grid-template-columns: 1fr;
  }

  .mision .mision-me {
    max-width: 100%;
  }
} 
@media screen and (max-width: 768px) {
  .mision {
    flex-direction: column; /*this line added in the lower media queries to ensure the layout adjust properly*/
    padding-bottom: 20%;
    padding-left: 5px;
  }

  .mision .mision-me {
    max-width: 100%;
  }
} 
  @media screen and (max-width: 480px) {
    .mision {
      flex-direction: column;
      padding-bottom: 50%;
      padding-left: 2px
    }
  
    .mision .mision-me {
      max-width: 100%;
    }
  }



  





