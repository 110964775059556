
  .contact
  {
    background: linear-gradient(45deg, rgb(210, 151, 255), #fff);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  
    

    margin: 16px 24px 300px 2px; 
    padding-bottom: 100px;
    padding-left: 32px;  
    display: flex;
 
  
  
  
  }

  input[type="submit"], textarea {

    background-color :#5a2c7e; 
  
  }
  
  input[value="Send Message"], textarea {

    color: #fff; 
 
  
  }

  form button {
    width: 100%;
    height: 55px;
    background: #826afb;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin-top: 25px;
    transition: .5s;
  }

  form button:hover {
    background: #624ec6;
  }